<template>
   <v-card
    class="mx-auto"
    max-width="1100"
  >
      <v-toolbar
      color="primary"
      dark
    >
      <v-toolbar-title>{{marathonData.name}}</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
     <!-- alert -->
    <!-- <v-alert
      color="info"
      text
      class="mb-0"
    >
      <div class="d-flex align-start">
        <v-icon color="info">
          {{ icons.mdiInformationOutline }}
        </v-icon>

        <div class="ms-3">
          <p class="text-base font-weight-medium mb-1">
            Your email is not confirmed. Please check your inbox.
          </p>
          <a
            href="javascript:void(0)"
            class="text-decoration-none info--text"
          >
            <span class="text-sm">Resend Confirmation</span>
          </a>
        </div>
      </div>
    </v-alert> -->
    <v-list two-line>
      <v-list-item-group  active-class="day-active">
        <template v-for="(item, index) in marathonDays">
            <v-list-item class="rest-day" v-if="item.type == 'rest'" :key="item.name">
              <v-list-item-content>
                <v-list-item-subtitle v-text="`День ${item.id}`"></v-list-item-subtitle>
                <v-list-item-title v-text="item.secondName" class="font-weight-bold"></v-list-item-title>
              </v-list-item-content>
                <v-list-item-action class="d-flex marathon-list-action">
                  <v-dialog
                    v-model="isDialogVisible[index]"
                    width="500"
                  >
                    <template #activator="{ on, attrs }">
                      <v-btn
                        v-if="item.motivationVideoUrl"
                        color="primary"
                        small
                        outlined
                        class="motivation-btn"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>
                        {{ icons.mdiPlay }}
                        </v-icon>
                        <span class="ms-3">Бонус</span>
                      </v-btn>
                    </template>

                    <v-card>
                      <v-card-text>
                        <video-player 
                        class="video-player-box"
                        oncontextmenu="return false;"
                        ref="videoPlayer"
                        :options="{
                          // videojs options
                          muted: true,
                          language: 'en',
                          fluid: true,
                          playbackRates: [0.7, 1.0, 1.5, 2.0],
                          sources: [{
                            type: 'video/mp4',
                            src: item.motivationVideoUrl
                          }],
                          poster: '',
                        }"
                        :playsinline="true"
                        >
                        </video-player>
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                  <v-btn
                    small
                    color="primary"
                    outlined
                    class="ms-3"
                    :href="`${item.menuUrl}`"
                    target="_blank"
                  >
                    <v-icon>
                    {{ icons.mdiFoodApple }}
                    </v-icon>
                    <span class="ms-3">меню</span>
                  </v-btn>
                  
                </v-list-item-action>
          </v-list-item>

          <v-list-item v-else :key="item.name" :to="{ name: 'daySingle', params: { marathon_id: marathonId, day_id: item.id, name: item.name, secondName: item.secondName, warmupVideoImage: item.warmupVideoImage, warmupVideoUrl: item.warmupVideoUrl, workoutVideoImage: item.workoutVideoImage, workoutVideoUrl: item.workoutVideoUrl } }">
              <v-list-item-content>
                <v-list-item-subtitle v-text="`День ${item.id}`"></v-list-item-subtitle>
                <v-list-item-title v-text="item.secondName" class="font-weight-bold"></v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon
                  v-if="!item.complete"
                  color="grey lighten-1"
                >
                  {{icons.mdiCheckboxMarkedCircle}}
                </v-icon>

                <v-icon
                  v-else
                  color="success"
                >
                    {{icons.mdiCheckboxMarkedCircle}}
                </v-icon>
              </v-list-item-action>
          </v-list-item>
          <v-divider
            v-if="index < marathonDays.length - 1"
            :key="index"
          ></v-divider>
        </template>
      </v-list-item-group>
    </v-list>
         <v-btn
      fab
      small
      class="fixed-br"
      color="transparent"
      href="https://t.me/+-d7pKTZK1Io4Njg1"
      target="blank"
    >
    <v-avatar>
      <v-img src="@/assets/images/logos/telegram-app-144.png"></v-img>
    </v-avatar>
    </v-btn>
  </v-card>
</template>

<script>
import store from '@/store'

import {
  mdiInformationOutline,
  mdiCheckboxMarkedCircle,
  mdiFoodApple,
  mdiPlay
} from '@mdi/js'
import router from '@/router'
import _ from 'lodash';
import 'video.js/dist/video-js.css'
import { videoPlayer } from 'vue-video-player'

export default {
  metaInfo() {
    return {
      title: `Marathon ${this.$route.params.marathon_id}`
    };
  },
  components: {
    videoPlayer,
  },
  data: () => ({
      marathonDays: null,
      isDialogVisible: [],
      playerOptions: {
        // videojs options
        muted: true,
        language: 'en',
        fluid: true,
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [{
          type: "video/mp4",
          src: ""
        }],
        poster: '',
      },
      progress: {},
      icons: {
        mdiInformationOutline,
        mdiCheckboxMarkedCircle,
        mdiFoodApple,
        mdiPlay
      },
    }),
  computed: {
    marathonId() {
      return this.$route.params.marathon_id
    },
    marathonData() {
      if(store.state.products.marathon[this.$route.params.marathon_id]) {
        return store.state.products.marathon[this.$route.params.marathon_id]
      } else {
        return {
          name: ''
        }
      }

    },
  },
  methods: {
    // listen event
    onPlayerPlay(player) {
      // console.log('player play!', player)
    },
    onPlayerPause(player) {
      // console.log('player pause!', player)
    },
    // ...player event

    // or listen state event
    playerStateChanged(playerCurrentState) {
      // console.log('player current update state', playerCurrentState)
    },

    // player is ready
    playerReadied(player) {
      // console.log('the player is readied', player)
      // you can use it to do something...
      // player.[methods]
    },
    onPlayerEnded(player) {
      // console.log('the player is ended', player)
      // you can use it to do something...
      // player.[methods]
    },
    onPlayerWaiting(player) {
      // console.log('the player is waiting', player)
      // you can use it to do something...
      // player.[methods]
    },
    onPlayerPlaying(player) {
      // console.log('the player is playing', player)
      // you can use it to do something...
      // player.[methods]
    },
    onPlayerTimeupdate(player) {
      // console.log('the player is time is updated', player)
      // you can use it to do something...
      // player.[methods]
    },
    onPlayerLoadeddata(player) {
      // console.log('the player is time is loaded', player)
      // you can use it to do something...
      // player.[methods]
    },
  },
  mounted() {
    let newData = {};
    store.dispatch('getMarathonDays',{
      marathonId: this.$route.params.marathon_id
    })
    .then((response) => {
      newData = _.orderBy(response, response.id, 'asc'); 
      // this.marathonDays = _.orderBy(response, response.id, 'asc'); 
      store.dispatch('getDayProgress',{
        marathonId: this.marathonId,
      })
      .then((progressResponse) => {
        if(progressResponse) {
          Object.keys(newData).forEach(key => {
            if( progressResponse[newData[key].id] ) {
              newData[key].complete = progressResponse[newData[key].id].isDayCompleted
            }
          })
        }
        this.marathonDays = newData
      })
      .catch((err) => {
        console.log(err)
      })
    })
    .catch((error) => {
      // router.push({ name: 'marathon' })
    });
  }
}
</script>

<style scoped lang="scss">
.day-active.v-list-item--active::before {
    opacity: 0;
}

.rest-day {
  background: rgb(0 0 0 / 5%);
}
</style>