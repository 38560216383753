var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"1100"}},[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.marathonData.name))]),_c('v-spacer')],1),_c('v-list',{attrs:{"two-line":""}},[_c('v-list-item-group',{attrs:{"active-class":"day-active"}},[_vm._l((_vm.marathonDays),function(item,index){return [(item.type == 'rest')?_c('v-list-item',{key:item.name,staticClass:"rest-day"},[_c('v-list-item-content',[_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(("День " + (item.id)))}}),_c('v-list-item-title',{staticClass:"font-weight-bold",domProps:{"textContent":_vm._s(item.secondName)}})],1),_c('v-list-item-action',{staticClass:"d-flex marathon-list-action"},[_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.motivationVideoUrl)?_c('v-btn',_vm._g(_vm._b({staticClass:"motivation-btn",attrs:{"color":"primary","small":"","outlined":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiPlay)+" ")]),_c('span',{staticClass:"ms-3"},[_vm._v("Бонус")])],1):_vm._e()]}}],null,true),model:{value:(_vm.isDialogVisible[index]),callback:function ($$v) {_vm.$set(_vm.isDialogVisible, index, $$v)},expression:"isDialogVisible[index]"}},[_c('v-card',[_c('v-card-text',[_c('video-player',{ref:"videoPlayer",refInFor:true,staticClass:"video-player-box",attrs:{"oncontextmenu":"return false;","options":{
                        // videojs options
                        muted: true,
                        language: 'en',
                        fluid: true,
                        playbackRates: [0.7, 1.0, 1.5, 2.0],
                        sources: [{
                          type: 'video/mp4',
                          src: item.motivationVideoUrl
                        }],
                        poster: '',
                      },"playsinline":true}})],1)],1)],1),_c('v-btn',{staticClass:"ms-3",attrs:{"small":"","color":"primary","outlined":"","href":("" + (item.menuUrl)),"target":"_blank"}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiFoodApple)+" ")]),_c('span',{staticClass:"ms-3"},[_vm._v("меню")])],1)],1)],1):_c('v-list-item',{key:item.name,attrs:{"to":{ name: 'daySingle', params: { marathon_id: _vm.marathonId, day_id: item.id, name: item.name, secondName: item.secondName, warmupVideoImage: item.warmupVideoImage, warmupVideoUrl: item.warmupVideoUrl, workoutVideoImage: item.workoutVideoImage, workoutVideoUrl: item.workoutVideoUrl } }}},[_c('v-list-item-content',[_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(("День " + (item.id)))}}),_c('v-list-item-title',{staticClass:"font-weight-bold",domProps:{"textContent":_vm._s(item.secondName)}})],1),_c('v-list-item-action',[(!item.complete)?_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCheckboxMarkedCircle)+" ")]):_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCheckboxMarkedCircle)+" ")])],1)],1),(index < _vm.marathonDays.length - 1)?_c('v-divider',{key:index}):_vm._e()]})],2)],1),_c('v-btn',{staticClass:"fixed-br",attrs:{"fab":"","small":"","color":"transparent","href":"https://t.me/+-d7pKTZK1Io4Njg1","target":"blank"}},[_c('v-avatar',[_c('v-img',{attrs:{"src":require("@/assets/images/logos/telegram-app-144.png")}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }